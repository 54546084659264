@import 'taku-variables';

%grid-horizontal-align {
    display: flex;
    flex-direction: row;
    align-items: center;
}
%box-shadow {
    box-shadow: 2px 2px 6px 1px rgba(0, 0, 0, 0.4);
}
%header-shadow {
    box-shadow: 0 4px 6px rgba(0,0,0,.5);
}

%page-subtitle {
    font-size: 18px;
    color: $textColor;
}

%open-dialog-btn {
    i {
        float: right;
        font-size: 0.8em;
        line-height: 1.5;
        color: $accentDarkColor;
    }
}

@mixin selectBtn-discountType() {
   ::ng-deep .p-selectbutton .p-button {
        height: 38pt;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
}

@mixin multiLine-button($numLines){
    height: $numLines * 1.4em;
    padding: 2px 6px;

    ::ng-deep .p-button-text {
        line-height: 1.2;
    }
}

@mixin mainPanel-light() {
    ::ng-deep > .p-panel{
        width: 100%;
        border-color: transparent-rgba-color(0.5, $primaryDarkColor);
        border-width: 1pt;
        // border-radius: 6px 6px 3px 3px;

        > .p-panel-titlebar {
            padding: 2pt 12pt;
            background-color: $accentLighterColor;
            // background-color: transparent-rgba-color(0.4, $primaryColor);

            > .p-panel-header {
                color: $primaryDarkColor;
                display: flex;
                align-items: center;
                h2 {
                    line-height: 1.15em;
                    font-size: 15pt;
                    font-weight: 500;
                    flex-grow: 1;
                }
            }
        }
    }
}

@function transparent-rgba-color($alpha, $color) {
    @return rgba(red($color), green($color), blue($color), $alpha)
}


@mixin disabled-input(){
    pointer-events: none;
    opacity: 0.5;
}

@mixin line-clamp($noLines) {
    /* autoprefixer: off */
    display: -webkit-box;
    -webkit-line-clamp: $noLines;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

@mixin inline-paragraphs() {
    p:first-child {
        display: inline;
    }
    p:last-child {
        margin-bottom: 0;
    }
}
@mixin controlset-vertical-divider {
    &:not(:last-child){
        // padding-bottom: 12px;
        border-bottom: 1px dotted $primaryLighterColor;
    }
}


///// LINES STYLES ////
@mixin tick-line($position) {
    border-#{$position}: 1px solid $line-color;
}

@mixin medium-line($position) {
    border-#{$position}: 1px dashed $line-color;
}

@mixin thin-line($position) {
    @if $position=="middle" {
        background-image: linear-gradient( to right, $line-color 50%, transparent 0%);
        background-position: 50%;
        background-size: 2px 1px;
        background-repeat: repeat-x;
    }
    @else {
        border-#{$position}: 1px dotted $line-color;
    }
}

@mixin thin-sparse-line($position) {
    background-image: linear-gradient(to right, $line-color 33%, transparent 0%);
    background-position: $position;
    background-size: 7px 1px;
    background-repeat: repeat-x;
}

@mixin double-line($position) {
    border-#{$position}: 3px double $line-color;
}

@mixin field-highlighted-readonly($color) {
    border-bottom: 2px solid $color;
    min-height: 1.5em;
}

@mixin field-highlighted-input($color) {
    border: 2px solid $color;
    outline: 0;
    ::ng-deep > * {
        width: calc(100% - 4px);
        min-width: calc(100% - 4px);
        background: none;
    }
}

%colored-field-label {
    color: $primaryColor;
    display: block;
}

%ptable-compactheader {
    p-table {
        // .buttons-left .buttons-group, .buttons-right {
        //     display: none;
        // }
        .headcell-wrapper {
            padding: 0;
            zoom: 0.85;
            .draggable-icon {
                display: none;
            }
        }
        .row-filters {
            th {
                padding: 0 0.25em !important;
            }
            .pi pi-search {
                zoom: 0.85;
            }
            // .p-inputtext {
            //     padding-left: 0;
            //     padding-right: 0;
            // }
        }
        .p-datatable-tbody>tr>td {
            // padding: 0 0.5em;
        }

    }
}

@mixin p-responsive-table {
    .p-datatable-responsive table {
       width: calc(100% - 1px);
    }

    .p-datatable-responsive .p-datatable-thead > tr th,
    .p-datatable-responsive .p-datatable-tfoot > tr td {
        display: none !important;
    }

    .p-datatable-responsive .p-datatable-tbody > tr td {
        text-align: left;
        display: flex;
        border: 0 none;
        width: 100% !important;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        float: left;
        clear: left;
    }

    .p-datatable-responsive .p-datatable-tbody > tr td .p-column-title {
        padding: .4em;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4em;
        font-weight: bold;
    }
}

@mixin responsive-table-column-caption {
    .p-datatable-responsive .p-datatable-tbody > tr td {
        display: flex;
        align-items: center;
    }
}

%taku-grid-table {
    border: 1px solid #CCC;
    border-width: 1px 1px 0 0;
    padding: 0;

    thead th {
        vertical-align: middle;
    }
    td, th {
        border: 1px solid #CCC;
        padding: 0.5em 0.75em !important;
        vertical-align: bottom;
    }
    td {
        border-width: 0 0 1px 1px;
    }
    th {
        border-width: 0 0 1px 1px;
        &.col-currency {
            border-left-width: 1px;
        }
    }
}

%taku-preview-fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    table {
        width: 48%;
        border: 1px solid $layoutMenuItemIconColor;
    }
    table td {
        padding: 0.5em;
        border-bottom: 1px solid $layoutMenuItemIconColor;
    }
    table tr:last-child {
        td {border-bottom: 0};
    }
    .label-col {
        font-weight: 700;
        width: 32%;
        background-color: rgba($primaryColor, 0.2);
    }
}

%bordered-container {
    border: 1.5pt solid $primaryDarkColor;
    border-radius: 6px;
}

%colored-inputfield {
    // background: lighten($primaryColor, 50%); // border: 2px solid #909090;
    border-radius: 4px;
    border: 1px solid $primaryLightColor;

    &:focus-within {
        border-color: $primaryDarkColor;
        border-width: 2px;
        margin: 0;
    }
}

@mixin taku-steps-visibility($width) {
    @media screen and (max-width: $width) {
        p-steps ::ng-deep {
            .p-steps ul {
                grid-column-gap: 10pt;
            }
            .p-steps-item {
                display: none;
    
                &.p-steps-current {
                    display: list-item;
                }
            }
        }
        .prev-step,
        .next-step {
            overflow: visible;
        }
        .prev-step {
            // flex-direction: column-reverse;
            .label-text {
                margin-left: 0;
            }
        }
        .next-step {
            // flex-direction: column;
            .label-text {
                margin-right: 0;
            }
        }
    }
}