/** Compare this file against 'src/assets/theme/theme-*.sass' when upgrading Ultima **/
$primaryColor: #3e464c;
$accentColor: #ffffff;
$accentTextColor: #ffffff;
$primaryLightestColor: #E3F2FD;
$primaryMenuTextColor: $primaryColor;
$primaryTextColor:#ffffff;

$primaryDarkColor: #2b3135;
$primaryLightColor: #777d81;
$accentDarkColor: #274d8d;
$accentLightColor: #86b0f5;

$inputFocusBorderColor: #3F51B5;
$tableBodyRowEvenBg: rgba(242, 242, 242);
$panelHeaderBg: $primaryDarkColor;
// $panelHeaderTextColor:$accentColor;
// $panelHeaderHoverBg:$primaryLightColor;


$highlightBg: rgb(251, 242, 224);
$emphasis-low:#ffffff;
// $emphasis-lower: rgba(200, 200, 200, .20);
