/* Add your variable customizations of theme here */
@import 'src/assets/sass/taku/_layout-colors.scss';
@import 'src/assets/sass/taku/_theme-colors.scss';
@import '../taku/_custom-colors';

$iconWidth:22px;
$iconHeight:22px;
$iconFontSize:22px;

/***  Inputs ****/
$inputVerticalInnerSpacing: 1.1em;
$inputHorizontalInnerSpacing: 1em;
$borderRadius-width: 4px;
$tableCellContentAlignment:center;
