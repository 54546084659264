$darkMenuBgColor: #2b3135;
$darkMenuHoverColor: #777d81;
$darkMenuRouterLinkActiveColor: #5180ce;
$lightMenuRouterLinkActiveColor: #5180ce;
$horizontalLightMenuRouterLinkActiveColor: #5180ce;


// Additional variables
$primaryDarkColor-hover: darken($primaryDarkColor, 10%);
$primaryDarkColor-focus: darken($primaryDarkColor, 15%);
$primaryLighterColor: lighten($primaryColor, 40%);
$primaryLightestColor: lighten($primaryColor, 66%);
$formListBgHeader: lighten($primaryColor, 48%);
$accentLighterColor: lighten($accentColor, 30%);
$ternaryThemeColor: #fac90c;
// Progress-bar colors
$color-progress-bar-bg: #f7f7f7;
$color-progress-bar: #4caf50;

////////////////////////////
// Invoce Docs (shared) ///
//////////////////////////
$color-btn-bg-ternary: $ternaryThemeColor;
$color-btn-bg-ternary-hover: darken($color-btn-bg-ternary, 10%);
$color-btn-bg-ternary-focus: darken($color-btn-bg-ternary, 15%);
$color-btn-font-ternary: #FFF;
$color-btn-font-ternary-hover: #EEE;
// Colors for Returns document
$color-btn-bg-returns: #ff4500;
$color-btn-bg-returns-hover: darken($color-btn-bg-returns, 10%);
$color-btn-bg-returns-focus: darken($color-btn-bg-returns, 15%);
$color-btn-font-returns: #eee;
$color-btn-font-returns-hover: #FFF;
// Colors for Salesscreen document
$color-btn-bg-salesregister: #34A835;
$color-btn-bg-salesregister-hover: darken($color-btn-bg-salesregister, 10%);
$color-btn-bg-salesregister-focus: darken($color-btn-bg-salesregister, 15%);
$color-input-bg-salesregister: desaturate(lighten($color-btn-bg-salesregister, 45%), 30%);
$color-input-bg-returns: desaturate(lighten($color-btn-bg-returns, 45%), 40%);
$color-btn-font-salesregister: #eee;
$color-btn-font-salesregister-hover: #FFF;
$color-btn-bg-preorders: rgb(48, 125, 219);
// Datatable
// $tableRowBgColorEven: $dataTableRowBgColorEven;
// $tableRowBgColorOdd: $contentBgColor;
// Status
$color-bg-success: #34A835;
$color-bg-warning: #ffba01;
$color-text-warning: darken(#ffba01, 15%);
$color-bg-error: #e91224;
// Inputs
$color-line-invalid-error: #e91224;
$color-text-invalid-error: #c01120;
// $color-input-placeholder: #999;
