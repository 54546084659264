/** Compare this file against 'src/assets/layout/css/layout-*.sass' when upgrading Barcelona **/
// $primaryColor: #3e464c;
// $primaryDarkColor: #2b3135;
// $primaryLightColor: #777d81;
// // $accentColor: #5180ce;
// $accentDarkColor: #274d8d;
// $accentLightColor: #86b0f5;
// $accentTextColor: #ffffff;
// $darkMenuBgColor: #2b3135;
// $darkMenuHoverColor: #777d81;
// $darkMenuRouterLinkActiveColor: #5180ce;
// $lightMenuRouterLinkActiveColor: #5180ce;
// $horizontalLightMenuRouterLinkActiveColor: #5180ce;
// $panelHeaderBg: $primaryColor;

