
/** Important: This file is being used by our own crafted Angular components,
    as a way to reuse colors and styles **/

/** Core variables **/
// @import '../variables';

@import 'src/assets/sass/taku/_layout-colors';
@import '../variables/layout/_layout_light';
@import 'src/assets/sass/taku/_theme-colors';
@import '../variables/theme/_theme_light';
/** New or overriden variables **/
@import '../overrides/_layout_variables';
@import '../overrides/_theme_variables';

$layoutMenuItemIconColor:#757575; // layout variable
$profileMenuDarkBorderBottomColor:#545454;

$textboxBgColor: #fff; // theme variable
$inputInvalidBorderColor:#e62a10;
$inputGroupBorderColor:#bdbdbd;
$inputFieldLabelTextColor:#999999;
$inputBorderErrorColor:#e62a10;
$inputBorderColor:#bdbdbd;
$contentIconColor:#757575;
$contentBorderColor:#bdbdbd;

$hoverTextColor:#000000;// sass variables
$grayBgColor:#757575;
$secondary:#ffffff;
$activeMenuItemBadgeColor:#ffffff;

$iconFontSize:1.5em;
$primaryTextColor:#ffffff;

$headerPadding:.714em 1em;
$headerBgColor:$primaryColor;
$headerTextColor:#ffffff;
$headerIconColor:#ffffff;
$headerFontWeight:500;
$headerFontSize:1em;

$contentPadding:.857em 1em;
$contentBgColor:#ffffff;
$contentFontSize:1em;
$inputInvalidBorderColor:#e62a10;
$inputBgColor:transparent;
$inputErrorTextColor:#e62a10;
$inputHeaderPadding:.714em 1em;
$inputFieldBoxBgColor:#f7f7f7;
$inputFieldFillBgColor:#f7f7f7;
$inputAutoFillBorderColor:#bdbdbd;

//inputs with lists
$inputListPadding: .5em 0;

//groups
$inputGroupBorderColor:#bdbdbd;
$inputGroupBgColor:transparent;
$inputGroupTextColor:$textSecondaryColor;
$inputGroupIconColor:$textSecondaryColor;
$inputGroupAddonMinWidth:2*$fontSize;
$checkboxWidth:18px;
$checkboxHeight:18px;
$inputGroupPadding:2px 2px 1px 2px;
$inputGroupIconFontSize: 1.5em;

//panels
$accordionHeaderBgColor:$primaryColor;
$accordionHeaderTextColor:#ffffff;
$accordionHeaderHoverBgColor:$primaryDarkColor;
$accordionHeaderHoverTextColor:#ffffff;

$panelContentLineHeight:1.5;

$buttonTextColor:#ffffff;

$listItemPadding:.571em .857em;

$radioButtonBorderColor:#757575;
$checkboxBorderColor:#757575;

$errorMessageFontSize:11px;
$errorMessageIconFontSize:13px;

//data
$dataTableHeaderPadding:.857em;
$dataTableCellPadding:.714em .857em;
$dataTableRowBgColorEven:#f4f4f4;
$paginatorPadding:.714em 1em;

//menus
$menuitemPadding:.571em .857em;
$menuListPadding: .5em 0;

// Google Shopping status button colors
$googleSuccessColor: #34A853;
$googleWarnColor: #FBBC05;
$googleInfoColor: #4285F4;
$googleErrorColor: #EA4335;

// Self-checkout kiosk colors
$selfCheckoutWhiteColor: #FFFDFA;
$selfCheckoutGreyColor: #EAE7DC;
$selfCheckoutLightBlackColor: #2B3135;

// Login page colors
$forestGreen: #005249;
$loginLightGrey: #9b9a9a;
// $loginLightGrey: rgba(0,0,0,0.87);
$loginLeftBgColor: #fffdfa;
$loginRightBgColor: #BA944E;
