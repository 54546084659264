@import "src/assets/sass/taku/taku-variables.scss";
@import "src/assets/sass/taku/taku-mixins.scss";

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}
.material-icons.md-24 {
  font-size: 24px;
}
.material-icons.md-36 {
  font-size: 36px;
}
.material-icons.md-48 {
  font-size: 48px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}
.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}
.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

/* You can add global styles to this file, and also import other style files */

/* Normalize Barcelona's icons with custom ones */
/* [class^="ui-icon-"]:before, [class*=" ui-icon-"]:before {
  vertical-align: middle;
} */

/* CSS specific to iOS devices */
@supports (-webkit-overflow-scrolling: touch) {
  /* Hack for fixing issue with PrimeNG's dialogs.
        See: https://css-tricks.com/forums/topic/safari-for-ios-z-index-ordering-bug-while-scrolling-a-page-with-a-fixed-element/
    */
  // body.sale-invoice-component {
  //     -webkit-transform: translate3d(0,0,0);
  // }

  // html{
  //     position: fixed;
  //     overflow: hidden;
  // }

  body,
  html {
    &.p-overflow-hidden {
      position: fixed;
      overflow: hidden;

      .p-dialog,
      .p-overlaypanel {
        /* enables “momentum” (smooth) scrolling */
        -webkit-overflow-scrolling: touch;
      }
    }
    touch-action: manipulation;
  }

  // body > app-root {
  //     display: block;
  //     width: 100vw;
  //     height: 100vh;
  //     overflow-y: auto;
  //     -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
  // }
}

/* Default size for custom icons */
[class^="takuicon-"]:before,
[class*=" takuicon-"]:before {
  font-size: 24px;
  margin: 0;
}

.btn-disabled,
.icon-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.takuicon-manage-stations-settings {
  &:before {
    font-size: 18px;
    width: 30px;
    padding-bottom: 10px;
  }
}

.takuicon-stock-settings {
  margin-top: -9px;
  margin-right: 12px;
  display: inline-block;

  &:before {
    font-size: 18px;
  }
}

.takuicon-active-station-settings,
.takuicon-active-location-settings,
.takuicon-manage-locations-settings,
.takuicon-cashout-history {
  &:before {
    font-size: 18px;
  }
}

.takuicon-integrations-settings::before {
  font-size: 24px;
}

.takuicon-collapse-row:before {
  font-size: 14px;
  line-height: 2.5em;
}

.takuicon-remove-filters:before {
  font-size: 15px;
  margin-left: -1px;
}

.p-button-icon-only .takuicon-calculator {
  top: 20%;
  left: 20%;
  margin-top: 0;
  margin-left: 0;
}

.p-dialog .p-dialog-content {
  padding: 1.25em;
}

.p-dialog {
  height: fit-content !important;
  max-height: 680px;
}

.drop-container {
  margin-top: 0;
}

// html {
//     width: 100vw;
// }

body.data-importer {
  overflow-x: hidden;
}

body .taku-button-small.p-button {
  font-size: 10pt;
  padding-top: 5px;
  padding-bottom: 5px;
  // color: $contentIconColor;

  &.p-button-icon-only .p-clickable {
    font-size: 1.83em;
  }
}

// .p-float-label textarea {
//   background-color: $textboxBgColor;
// }

.layout-wrapper
  .layout-sidebar
  .layout-tabmenu
  .layout-tabmenu-contents
  .layout-tabmenu-content
  .layout-submenu-content
  .navigation-menu
  li
  a {
  display: flex;

  span {
    word-break: break-word;
    flex-grow: 1;
    padding-top: 3px;
  }
}

.grid-pull-right {
  display: flex;
  justify-content: flex-end;
}

.grid-pull-left {
  display: flex;
  justify-content: flex-start;
}

.p-panel .p-panel-content {
  padding-top: 0.75rem;
}

body .panel-nopad {
  > * > .p-panel-content {
    padding: 0;
  }

  > .p-card-body {
    padding: 0.5em;
  }

  .p-tabview {
    border: 0;
  }
}

body .tabview-fluid {
  width: 100%;
}

body .tabview-nopad {
  .p-tabview-panel {
    padding: 0 !important;
  }
}

body .tabview-smallpad {
  .p-tabview-panel {
    padding: 0.4em 0.6em 0.6em !important;
  }
}

body .top-tabview-nopad {
  > .p-tabview > .p-tabview-panels > p-tabpanel > .p-tabview-panel {
    padding: 0 !important;
  }
}

body .unactiveLink {
  pointer-events: none;
}

body a.text-link {
  color: $primaryColor;
  text-decoration: underline;
  font-size: 13px;
  font-weight: 700;
}

// Push down close icon in order to not be blocked by toolbar
p-sidebar {
  h1 {
    margin: 0;
  }

  // >.p-overlaypanel {
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // }

  // .p-overlaypanel-close {
  // position: absolute;
  // left: 0;
  // top: 50%;
  // margin-left: -10px;
  // -webkit-transform: translateY(-50%);
  // border: 1px solid gray;
  // padding: 20px 0px 14px;
  // background: #0D47A1;
  // span {
  //     color: white !important;
  // }
  // >span::before {
  //     content: "chevron_right" !important;
  // }
  // }
}

h1,
h2 {
  .secondary-text {
    color: $ternaryThemeColor;
    margin-left: 0.2em;
    text-transform: none;
    font-weight: normal;
    display: inline-block;
  }
}

p-toolbar {
  // background-color: #2b3135;
  h1 {
    display: inline;
    vertical-align: middle;
    font-weight: normal;
    margin-left: 0.5em;
    // text-transform: uppercase;
    font-size: 22px;
    font-weight: 700;
  }

  .page-toolbar {
    margin-bottom: 10px;
  }
}

p-panel .p-panel-header {
  justify-content: space-between;
  font-size: 1.2em;
  h3 {
    color: $accentTextColor;
  }
  h2 {
    display: inline-block;
    font-weight: normal;
    font-size: 1.2rem;
    margin: 0.25em 0;
    color: $accentTextColor;
  }

  i {
    margin-right: 6px;
    vertical-align: text-bottom;
  }

  taku-checkbox > span > div {
    padding: 0;
  }
}

/* Taku Calendar Fix after PrimeNG update */
// p-calendar {
//     .p-datepicker-trigger {
//         position: absolute;
//     }
// }

/* Remove white from dropdowns because of Barcelona Theme */

body p-dropdown .p-dropdown-label,
.p-multiselect .p-multiselect-label.p-placeholder {
  color: $darkMenuBgColor;
  text-align: left;
  padding: 0.75rem;
}

/* Override PrimeNG's File Uploader styles*/

// body .p-fileupload .p-fileupload-buttonbar .p-button {
//   color: $darkMenuBgColor;

//   .pi {
//     color: $darkMenuBgColor;
//   }
// }

.p-inputtext:not(.p-chips-multiple-container),
.p-component.p-dropdown {
  background: transparent;
  height: 40px;
}

.p-inputgroup-addon.chg-border {
  height: 40px;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  height: 2.5rem;
}

.p-dialog-header {
  height: fit-content !important;
  position: relative;
}

.p-dialog-header .p-dialog-header-icons {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(40%, -40%);
  z-index: 99999;
  background: $primaryColor;
  justify-content: center;
  .p-dialog-header-icon {
    width: 2.432rem;
    height: 2.432rem;
  }
}

// .p-inputgroup:not(.p-disabled):hover {
//   border-color: rgba(0,0,0,0.87);
// }

.inputgroup-outlined {
  @extend %colored-inputfield;
}

.inputgroup-outlined:focus-within {
  border-color: $accentDarkColor;
}

.p-autocomplete-panel {
  overflow: auto;
  position: absolute;
}

.cols-aligned {
  display: flex;
  flex-wrap: wrap;
}

/** Fix PrimeNG's Chips paddign issues **/

body .p-chips > ul.p-inputtext .p-chips-token {
  // margin-bottom: 3px;
  // margin-right: 3px;
}

body .p-chips > ul.p-inputtext {
  background-color: unset;
}

/** Global PrimeNG Sidebar's styles  **/

.p-overlaypanel.p-component-content {
  overflow-y: auto !important;
}

/** Unify styling for disabled input fields **/

// .p-dropdown.p-disabled {
// border-bottom: 1px dotted;
// background-color: #fcfcfc;
// background-image: none;
// opacity: 1;
// }

body .p-inputgroup .p-float-label {
  width: 100%;
}

.header-button {
  margin: 0 0.25em !important;
}

.p-overlaypanel {
  // margin-left: 1em;
  .p-overlaypanel-content {
    padding: 0.8em 1em;
  }
}

.p-datatable-header,
.p-toolbar-group-right,
.p-toolbar-group-left {
  .p-button.p-button-icon-only.p-button-rounded {
    height: 2.5rem !important;
    width: 2.5rem !important;
    flex-shrink: 0;
  }
}

.p-datatable .p-datatable-tbody > tr:not(.p-highlight):focus {
  background-color: $primaryLightestColor;
}
.p-float-label > .ng-invalid.ng-dirty + label {
  color: $color-line-invalid-error !important;
}

.p-float-label > label {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  // left: calc(var(--overlayArrowLeft, 0) + 1rem) !important;
  display: none;
}

body button.p-button.squared-corners {
  border-radius: 4px;
}

// Form list expansion when put alone on page
// .layout-main-content > taku-form-list-main {
//   // display: block;
//   // height: calc(100vh - 75px);
//   .table-container {
//     padding-top: 0;
//     padding-bottom: 0;
//   }
// }
body .p-selectbutton .p-button {
  background-color: $primaryColor;
  color: white;
}

body .p-selectbutton .p-button {
  background-color: white;
  color: $darkMenuBgColor;
}

body .p-selectbutton .p-button:not(.p-disabled):hover {
  background-color: darken($color: white, $amount: 15%);
}

body .p-selectbutton .p-button:not(:last-child) {
  border-right: solid #cccccc 0.1em;
}

// Fix visual styling of disabled inputs (search filter)

body p-selectButton .vertical-stack {
  display: flex;
  flex-direction: column;

  .p-button {
    margin: 1px 0;
  }
}

body .taku-tablestyle-controllist {
  h5 {
    margin-bottom: 0 !important;
  }

  table {
    border: 1px solid $grayBgColor;

    tr {
      display: flex;
      // align-items: flex-end;
    }

    td,
    th {
      display: flex;
      align-items: flex-end;
      border-left: 1px dashed $contentBorderColor;

      &:first-child {
        border: 0;
      }

      padding: 12pt 6pt 6pt;
      height: 100%;
    }

    thead tr {
      border-bottom: 1px solid $grayBgColor;

      th {
        color: $profileMenuDarkBorderBottomColor;
        font-weight: 500;
      }
    }

    tbody tr {
      border-bottom: 1px solid $contentBorderColor;
      align-items: center;

      &:last-child {
        margin-bottom: 0;
        border-bottom: 0;
      }
    }

    tfoot td {
      justify-content: flex-end;
      font-weight: bold;
      border-top: 1px solid $contentBorderColor !important;
    }

    .col-remove-btn {
      align-items: center;
      width: 35px;
      height: auto;

      a {
        cursor: pointer;
      }

      i {
        color: red;
      }

      .disabled {
        opacity: 0.5;
      }
    }
  }
}

body .taku-openingfloat-table.taku-tablestyle-controllist {
  .col-count {
    .p-float-label {
      margin-top: 0;
    }
  }

  .col-total,
  .col-currency {
    justify-content: flex-end;
  }

  td {
    height: 100%;
  }

  td,
  th {
    border-left: 0;
  }

  tfoot td {
    font-size: 18px;
    line-height: 1.5em;
    justify-content: flex-end;
  }

  .col-timessign,
  .col-equalsign {
    justify-content: center;
  }
}

body .address-details {
  p-card .p-card-body {
    padding: 0;
  }

  .address-header {
    margin: 14px;
  }

  p-tabView .p-tabview-panel {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

body .taku-expanded-gridcols-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;

  .p-panel {
    height: 100%;
    background: $accentTextColor;
  }
}

.p-toolbar,
.p-picklist-header {
  color: $accentTextColor !important;
}

body .fields-divider {
  border-color: transparent;
  border-bottom: 1px solid $primaryLighterColor;
  padding-top: 12px;
  margin-bottom: 12px;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

// Shared address component
body .info-toggle-wrapper {
  display: flex;
  align-items: center;
}

body .info-toggle {
  taku-toggle {
    display: inline-block;
  }

  > i {
    font-size: 1.25em;
    vertical-align: bottom;
    margin-left: 1.5pt;
  }
}

body .display-field {
  label {
    color: $primaryColor;
    font-size: 12px;
  }

  .value {
    line-height: 1.5;
  }
}

body .tabular-displaymode {
  display: contents;
}

// Global styles for modals
.leftside-modal {
  left: 48% !important;
  transform: translateX(-100%) !important;
}

.rightside-modal {
  left: 52% !important;
}

.inventory-gallery-dialog {
  .p-dialog-titlebar-close {
    margin: 0 !important;
  }
}

i.locked-feature {
  color: $primaryLightColor;
}

.inputctrl-wrapper {
  display: flex;

  > i.locked-feature {
    width: 10pt;
    margin-top: 4pt;
  }
}

.revisit-dialog {
  max-width: calc(100% - 100px);
}

.storesOfZoneOverlay {
  button.p-overlaypanel-close {
    zoom: 0.8 !important;
  }
}

@media (max-width: 1434px) {
  .p-steps .p-steps-item:before {
    display: none;
  }
}

/* iPad Air landscape */
@media only screen and (min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  // .dialog-numPad .p-dialog-content {
  //     min-height: 95vh;
  // .p-dialog-content {
  //     height: 100%;
  // }
  // }
}

@media (max-width: 64em) {
  .fullscreen-mobilemodal {
    width: 100% !important;
    height: 100% !important;
    // height: calc(100vh - 30pt) !important;
    // bottom: calc(-50% + 15pt);
    // top: auto;

    .p-dialog-content {
      height: 100% !important;
      width: 100% !important;
      max-height: none !important;
      min-width: auto !important;
    }

    .p-dialog-titlebar + .p-dialog-content {
      height: calc(100% - 40pt) !important;
    }
  }
}

.dialogForNewItemInSaleScreen {
  width: 100% !important;
  height: 90vh !important;
}

.selfcheckout-desktop {
  // width: 80%;
  max-width: 992px;
  // max-width: 700px;
  height: 90%;
  max-height: 1100px;
}

.selfcheckout-mobile {
  .p-dialog-content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 704px) {
  .selfcheckout-mobile {
    width: 90%;
    min-width: 280px;
  }
}

@media only screen and (min-width: 705px) and (max-width: 991px) {
  .selfcheckout-mobile {
    width: 80%;
  }
}

.selfcheckout-shopping-cart {
  .p-dialog-content {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
  }
}

.rounded-selfcheckout-dialog {
  border-radius: 25px;
  .p-dialog-header {
    border-top-left-radius: 25px !important;
    border-top-right-radius: 25px !important;
    // .p-dialog-header-icons {
    //   zoom: 2;
    // }
  }
  .p-dialog-content {
    // border-top-left-radius: 25px !important;
    // border-top-right-radius: 25px !important;
    border-bottom-left-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }
}

@media only screen and (min-width: 641px) {
  .rounded-selfcheckout-dialog {
    .p-dialog-header {
      .p-dialog-header-icons {
        zoom: 2;
      }
    }
  }
}

p-splitButton {
  .p-button-secondary {
    border: 1px solid $grayBgColor;
  }
}

taku-form-list p-paginator .p-dropdown {
  width: auto !important;
  min-width: auto !important;
}

// Styles for secondary buttons (dark blue color)
body .p-button.p-button-primary-dark,
body .p-buttonset.p-button-primary-dark > .p-button {
  color: $primaryTextColor !important;
  background-color: $primaryDarkColor;
}

body .p-button.p-button-primary-dark:enabled:hover,
body .p-buttonset.p-button-primary-dark > .p-button:enabled:hover {
  background-color: $primaryDarkColor-hover;
  color: $primaryLightColor;
}

body .p-button.p-button-primary-dark:enabled:focus,
body .p-buttonset.p-button-primary-dark > .p-button:enabled:focus {
  background-color: $primaryDarkColor-focus;
}

// Styles for Ternary button (yellow)
body .p-button.p-button-ternary-custom,
body .p-buttonset.p-button-ternary-custom > .p-button {
  color: $color-btn-font-ternary;
  background-color: $color-btn-bg-ternary;
}

body .p-button.p-button-ternary-custom:enabled:hover,
body .p-buttonset.p-button-ternary-custom > .p-button:enabled:hover {
  background-color: $color-btn-bg-ternary-hover;
  color: $color-btn-font-ternary-hover;
}

body .p-button.p-button-ternary-custom:enabled:focus,
body .p-buttonset.p-button-ternary-custom > .p-button:enabled:focus {
  background-color: $color-btn-bg-ternary-focus;
}

// Styles for RETURN document (orange color)
body .p-button.p-button-returns-custom,
body .p-buttonset.p-button-returns-custom > .p-button {
  color: $color-btn-font-returns;
  background-color: $color-btn-bg-returns;
}

body .p-button.p-button-returns-custom:enabled:hover,
body .p-buttonset.p-button-returns-custom > .p-button:enabled:hover {
  background-color: $color-btn-bg-returns-hover;
  color: $color-btn-font-returns-hover;
}

body .p-button.p-button-returns-custom:enabled:focus,
body .p-buttonset.p-button-returns-custom > .p-button:enabled:focus {
  background-color: $color-btn-bg-returns-focus;
}

// Styles for SALES document (green color)
body .p-button.p-button-salesregister-custom,
body .p-buttonset.p-button-salesregister-custom > .p-button {
  color: $color-btn-font-salesregister;
  background-color: $color-btn-bg-salesregister;
}

body .p-button.p-button-salesregister-custom:enabled:hover,
body .p-buttonset.p-button-salesregister-custom > .p-button:enabled:hover {
  background-color: $color-btn-bg-salesregister-hover;
  color: $color-btn-font-salesregister-hover;
}

body .p-button.p-button-salesregister-custom:enabled:focus,
body .p-buttonset.p-button-salesregister-custom > .p-button:enabled:focus {
  background-color: $color-btn-bg-salesregister-focus;
}

label.field-caption {
  font-size: 12px;
  color: $accentDarkColor;
  display: block;
}

.grid-align-right {
  @extend %grid-horizontal-align;
  justify-content: flex-end;
}

.grid-align-left {
  @extend %grid-horizontal-align;
  justify-content: flex-start;
}

.divider-dot {
  height: 6px;
  width: 6px;
  background-color: #aaa;
  border-radius: 50%;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;

  &.small {
    height: 4px;
    width: 4px;
  }
}

.taku-button-large {
  font-size: 1.2em;
}

.remove-button {
  color: $color-bg-error;

  &.disabled {
    opacity: 0.5;
  }
}

////////////////////////////
// App Cues integration ///
//////////////////////////
// li.appcues-nothing-new {
//     display: none;
// }

// .appcues-widget .appcues-widget-icon {
//     @include material-icon('inbox');
//     color: $primaryTextColor;
//     font-size: 26pt;
// }

// .appcues-widget-content {

//     // Hides the date
//     a[data-itemid] time {
//         display: none;
//     }

//     .appcues-widget-list {
//         border-bottom: 0;
//     }
// }

/// END of App Cues ///

#rightsideDialog {
  .tab-header {
    background-image: url("assets/layout/images/sales-doc/dialogtab-pushdown.png");
  }
}

a.disabled-link {
  pointer-events: none;
  opacity: 0.5;
  cursor: pointer;
}

.disabled-label {
  opacity: 0.5;
}

div[role="alert"] {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  .p-toast-icon-close {
    align-self: flex-end;
  }
}

body .dialogSidebar {
  // max-height: 90%;
  // height: calc(100vh - 45pt);
  // max-height: calc(100vh - 10pt);
  padding-left: 5pt;
  padding-right: 5pt;
  padding-top: 0;

  .p-overlaypanel-close {
    float: left;
    margin-bottom: 8pt;

    & + * {
      clear: both;
      display: block;
    }
  }

  .p-dialog-content {
    padding: 0;
  }

  .p-dialog-titlebar .p-dialog-titlebar-close {
    float: left;
    margin: 0;
    margin-left: -10pt;
  }
}

body .sidebarDocLine {
  .p-overlaypanel-close {
    background-color: $primaryDarkColor;
    position: absolute;
    left: 10pt;
    z-index: 10;
  }
}

// Universal Styling for scroll bar

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: $inputBorderColor;
  opacity: 1;
  transition: background-color 0.5s;
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  border-right: 9px solid $bodyBgColor;
  padding-right: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: $primaryDarkColor;
}

.custombar-salesscreen {
  .p-scrollpanel-wrapper {
    // border-right: 9px solid $bodyBgColor;
    padding-right: 2px;
  }

  .p-scrollpanel-bar {
    background-color: $inputBorderColor;
    opacity: 1;
    transition: background-color 0.5s;
  }

  .p-scrollpanel-bar:hover {
    background-color: $primaryDarkColor;
  }

  .p-scrollpanel-content {
    overflow: scroll;
    padding-right: 0;
  }
}

// Salesscreen
body.sale-invoice-component,
body.return-invoice-component {
  #layout-main-content {
    padding-bottom: 0;
  }
}

app-breadcrumb {
  display: none;
}

body .input-noCaption .p-float-label {
  margin-top: 0 !important;
}

body .disabledBtn {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

/*********************** /
/** General UI styles **/
/**********************/
body .taku-expandable-block {
  .expansion-fields {
    overflow: hidden;
    height: auto;
    transition: ease-in-out 0.5s height;
    margin-top: -10pt;

    .taku-listable-container {
      margin-top: 17pt;
    }
  }

  .container-hidden {
    height: 1em !important;
  }
}

body .taku-listable-container {
  position: relative;
  // margin-top: 15pt;

  .p-fieldset {
    position: relative;
    border-color: $primaryLighterColor;

    .p-fieldset-header {
      padding: 0;
    }

    legend h5 {
      color: $primaryColor;
      font-weight: normal;
    }
  }

  .p-fieldset-legend,
  .add-button-container {
    padding: 0;
    background-color: transparent;
  }
}

.p-fieldset > .p-fieldset-legend {
  background: transparent;
}

.add-button-container {
  // position: absolute;
  // right: 1.9%;
  // top: 2px;
  // display: grid;
  background: white;
  // grid-auto-flow: column;
  // justify-content: end;
  // grid-gap: 12pt;
  text-align: right;
  margin-right: -1em;
  margin-bottom: 0.4em;
}

.add-button {
  // margin-left: 12px;
  // padding-bottom: 0;
  // padding: 0 4pt;

  > * {
    cursor: pointer;
  }

  i {
    vertical-align: top;
    margin-top: -2pt;
  }

  label {
    text-align: right;
    vertical-align: top;
  }

  label,
  .p-button-text {
    // text-transform: uppercase;
    // font-size: 13px;
    margin-right: 0.2em;
  }

  .p-button-text {
    margin-right: 0.5em;
  }
}

// .p-inputgroup.inputgroup-outlined .p-float-label .p-component.p-inputtext:not(.p-listbox),
// .p-float-label>*>.p-component.p-dropdown,
// .p-float-label .p-component.p-inputtext:not(.p-listbox) {
//   padding: 1em !important;
// }

.taku-listable-controlset {
  //background-color: #f3f3f3;
  // margin-bottom: 24px;
  display: flex;
  align-content: space-between;
  width: calc(100% - 10pt);
  // margin-bottom: 10pt;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }

  > *:first-child {
    flex-grow: 1;
  }

  .remove-button-container {
    // background-color: white;
    // border: 1px solid #d5d5d5;
    align-items: center;
    display: flex;
    justify-content: flex-end;
    width: auto;
    padding-left: 0.25em;

    // .remove-button {
    //   margin-top: 10px;
    //   margin-right: 2px;
    //   // margin-left: 15px;
    // }

    a {
      cursor: pointer;
    }
  }
}

body .address-tabview-container {
  position: relative;
  min-height: 45pt;

  .address-action-btns {
    position: absolute;
    right: 0.5em;
    top: 20pt;
    width: 100%;
  }

  p-tabView .p-tabview-nav li {
    border-top: 1px solid lightgrey !important;
  }
}

.p-inputgroup:not(.login):not(.amount-field):not(.change-qty):not(.chg-amount) {
  .p-float-label:first-child input {
    border: none;
    box-shadow: none;
  }
}

.inputgroup-outlined {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 4px;
}

body .dialog-singleline-btn {
  @extend %open-dialog-btn;
  padding: 0.5em 0.8em;

  &.p-button-secondary {
    i {
      color: $primaryColor;
    }
  }
}

body .dialog-multiline-btn {
  @extend %open-dialog-btn; // height: 4em;
  line-height: 1.25em;
  padding: 0.8em;
  margin-bottom: 0.8em;
  text-align: center;

  small {
    display: block;
    color: $accentDarkColor;
  }

  strong {
    font-size: 16px;
    font-weight: 700;
  }
}

body .taku-warningMsg {
  margin-top: 3pt;

  .p-message {
    margin: 0;
    width: 100%;
  }

  .p-message-icon {
    color: $textSecondaryColor;
  }

  .p-message-text {
    color: $textColor;
  }
}

body .taku-megaBtn {
  height: auto;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .btn-icon {
    margin-right: 0.25em;
    font-size: 1.1em;
  }

  .p-button-text {
    width: 0;
  }
}

// Global changes for Account field
.p-inputgroup.inputgroup-outlined .p-float-label:focus-within + label {
  color: $accentDarkColor !important;
}

p-card .p-card-header {
  background: $primaryColor;
  color: $accentTextColor;
}

// Statistics cards/panels
.statistics-card {
  .p-card-header,
  .p-card-footer {
    text-align: center;
    display: block;
  }

  .p-card-header {
    line-height: 1;
    font-weight: 300;
    font-size: 1.2em;
  }

  // p-footer {}
  .main-content {
    font-size: 2.5vh;
    text-align: center;
    line-height: 2;
  }
}

// Global styling for ellipsis
.p-dropdown .p-dropdown-label-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  max-width: 100%;
}

.p-dropdown-label-container .p-dropdown-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-overlaypanel .p-overlaypanel-close {
  width: 2.432em;
  height: 2.432rem;
  z-index: 9999;
  zoom: 0.99 !important;
  span.pi-times:before {
    content: "\e90b" !important;
  }
}

.pi.pi-chevron-down:before {
  padding: 0 0.2em;
}

.p-fluid .p-button-text-icon-left .p-button-text {
  padding-left: 0;
  padding-right: 0;
}

// add in component.scss
//   button[ng-reflect-icon="ui-icon-email"], button[ng-reflect-icon="ui-icon-print"] {
//     border-radius:4px
//   }

//   body .p-inputgroup.inputgroup-outlined .p-float-label .widget-outlined{padding:1em 1em;

//     .p-datepicker-buttonpane>button{overflow:hidden;background-color:#fff;color:#3e464c;font-size:1em;height:2.143em;font-family:"Roboto","Helvetica

//   @media only screen and (min-device-width: 375px) and (max-device-width: 1024px){
//   .p-inputgroup-addon{margin-bottom:1px}
//   .p-inputgroup-addon{margin-bottom:0}
//   }

// @media only screen and (max-width: 1230px) and (min-width: 1024px) {
//   label[for="stockId"] {
//     width: 60px;
//   }

//   .p-dropdown {
//     max-width: 12em;
//   }
// }

// ui-lg and above
@media (min-width: 64.063em) {
  // body .formlist-panelOverlay {
  // &::before, &::after {
  //     left: 1.25em;
  //     right: auto;
  //     margin-right: auto;
  // }
  // &::before {
  //     margin-left: -10px;
  // }
  // &::after {
  //     margin-left: -8px;
  // }
  // }
}

// ui-md and below
@media screen and (max-width: 64em) {
  // Decrease spacing around panels
  body .p-panel.p-panel-content,
  body .p-tabview .p-tabview-panel {
    padding: 0.5em 0.25em;
  }

  // App Cues
  // .appcues-widget .appcues-widget-icon {
  //     color: $textColor;
  // }

  .p-overlaypanel.p-overlaypanel-lg {
    &.p-overlaypanel-left,
    &.p-overlaypanel-right {
      min-width: 65%;
    }
  }

  p-fieldset .taku-listable-controlset {
    @include controlset-vertical-divider;
  }
}

*[hidden] {
  display: none !important;
}

.search-box-btns .p-fluid .p-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.layout-wrapper .layout-main .layout-content {
  padding: 1rem;
  padding-top: 0.5rem;
}

//ui-sm (mobile) and below
@media screen and (max-width: 40em) {
  body .my-toolbar {
    padding: 5pt;
  }

  // .p-button.p-button-icon-only.p-button-rounded {
  //   height: 1.8rem;
  //   width: 1.8rem;
  //   i {
  //     font-size: 1.2em;
  //   }
  // }

  // .p-toolbar {
  //   justify-content: space-around;
  // }

  // .inner-header .buttons-right {
  //   display: block !important;
  // }

  .takuicon-integrations-settings {
    &::before {
      font-size: 18px;
    }
  }

  .custombar-salesscreen {
    .p-scrollpanel-bar {
      background-color: transparent;
    }

    .p-scrollpanel-content {
      padding-right: 16px;
    }

    &:hover {
      .p-scrollpanel-bar {
        background-color: $inputBorderColor;

        &:hover {
          background-color: $primaryDarkColor;
        }
      }
    }
  }

  .p-overlaypanel.p-overlaypanel-lg {
    &.p-overlaypanel-left,
    &.p-overlaypanel-right {
      min-width: 80%;
    }
  }

  app-sidebar .layout-tabmenu-nav li {
    > a.tabmenuitem-link {
      height: 60px !important;
    }

    p {
      display: none;
    }
  }

  .leftside-modal,
  .rightside-modal {
    left: 2% !important;
    transform: translateX(0%) !important;
  }
}

// Global styles for progressBar
#data-progressbar {
  padding: 3px;
}

#data-progressbar > p-progressBar {
  .p-progressbar {
    background-color: $color-progress-bar;
    height: 5px;
    border-radius: 0 !important;

    .p-progressbar-value {
      background-color: $color-progress-bar;
    }

    .p-progressbar-value::before {
      background-color: $color-progress-bar-bg;
    }
  }
}

.p-autocomplete {
  width: 100%;
}

.p-dialog-header {
  .p-dialog-header-icons {
    background: $primaryColor;
    border-radius: 50%;
    .p-dialog-header-icon,
    .p-dialog-header-close {
      color: $accentTextColor;
    }
  }
}

@media only screen and (min-width: 641px) {
  .longDescription-dialog {
    width: 75%;
  }
}

.tooltip-styling {
  color: $textColor;
  text-align: left;
  font-size: 10pt;
  max-width: 190pt;
  line-height: 1.35em;

  h5,
  h6 {
    font-size: 11pt !important;
    margin: 0.5em 0;
  }

  ul {
    margin-top: 0;
    padding: 0 0 0 1em;
  }

  li {
    margin: 0.5em 0;
  }
}

.delete-icon {
  vertical-align: middle;
}

.formlist-sidebar {
  .p-sidebar-content {
    height: 100%;
  }
}

.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}

@media only screen and (max-width: 375px) {
  .form-list-dialog-style {
    width: 100%;
  }
}

@media only screen and (min-width: 376px) and (max-width: 640px) {
  .form-list-dialog-style {
    width: 95%;
  }
}

.rolePermission-dialog-style {
  width: 90%;
}

@media only screen and (min-width: 641px) and (max-width: 991px) {
  .rolePermission-dialog-style {
    width: 75%;
  }
}

@media only screen and (min-width: 992px) {
  .rolePermission-dialog-style {
    width: 60%;
  }
}

.terminalPairUnpairProgressDlg {
  width: 80vw;
}

// PrimeNg 14 added styles to hide placeholder until focused, this overrides it.
.p-float-label {
  .p-placeholder,
  input::placeholder,
  .p-inputtext::placeholder {
    opacity: 1;
  }
}

.p-overlaypanel.p-component {
  @media (max-width: 730px) {
    width: 90%;
  }
}

@media screen and (max-width: 640px) {
  #pr_id_16-table > .p-datatable-tbody > tr > td {
    justify-content: normal;
  }
}

.p-column-title {
  font-weight: bold;
  flex: 0 0 35%;
  text-align: left;
}

// Primeflex stylesheet seems to not include the documented "gap" classes
// https://www.primefaces.org/primeflex/gap
.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.5rem;
}

.gap-6 {
  gap: 2rem;
}

.vertical-align-middle {
  vertical-align: middle;
}

.numpad-header {
  background: $primaryDarkColor;
  display: flex;
  justify-content: left;
  color: white;
  align-items: center;
  h3 {
    margin: 0;
    font-weight: $panelHeaderFontWeight;
  }
}

.numpad-content {
  width: 350px;
}

.-translate-x-50 {
  transform: translateX(-50%);
}
